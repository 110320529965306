<template>
  <div class="newsList">
    <div class="conent">
      <div class="lbt">
        <img src="../assets/img/banner.jpg" />
      </div>
      <div class="w1380 lmy">
        <div class="lmy_box">
          <div class="lmy-right">
            <div class="lmy-right-list">
              <div class="zlm">
                <p><span class="ellipsis" style="width:62.5vw;">{{$route.query.title}}</span></p>
              </div>
            </div>
          </div>
          <div class="lmy-center">
            <div class="lmy-left 111">
              <div class="lmy-left-bottom">
                <div class="news-list 14">
                  <div
                    class="news-list-item"
                    v-for="(item, index) in list"
                    :key="index"
                    @click="toDetail(item)"
                  >
                    <div class="list-date">
                      <p>{{$moment(item.createTime).format('YYYY.MM.DD').split('.')[2]}}</p>
                        <p>{{$moment(item.createTime).format('YYYY.MM.DD').slice(0,7)}}</p>
                      
                    </div>
                    <div class="list-title">
                      <div
                        class="li_bt"
                        :title="item.title"
                      >
                        {{item.title}}
                      </div>
                      <!-- <p class="li_zy">
                        10月10日，我校党委理论学习中心组召开专题读书会，深入学习《习近平谈治国理政》第四卷。
                      </p> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="lmy-page">
            <Pagination @pageChange="pageChange" :totalNum="total" :currentPage="pageNum"></Pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import Pagination from "../components/Pagination";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
        total: 0,
        pageNumber:0,
        list: []
    };
  },
  created() {
    this.setScroll();
    this.getlist();
  },
  methods: {
    async getlist() {
      const res = await this.API.basic.getlist({
        columnIds: this.$route.query.columnId,
        pageNumber: this.pageNumber,
        pageSize: 10,
      });
      this.list = res.data?.content;
      this.total = res.data.totalElements
    },

    toDetail(item) {
      this.$router.push({
        path: "/ztDetail",
        query: {
          title:item.title,
          id: item.id,
          columnId: item.columnId
        },
      });
    },
    // 滚动条初始化高度设置为0
    setScroll() {
      this.$nextTick(() => {
        document.documentElement.scrollTop = 0;
      });
    },
    pageChange(val) {
      this.pageNumber = val-1
      this.getlist()
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.newsList {
  overflow-y: auto;
  background: #f5f5f5;
  padding-bottom: 50px;
  .w1380 {
    width: 1380px;
    margin: 0 auto;
  }
  .conent .lbt {
    // z-index: -1;
    position: relative;
  }
  .conent .lbt img {
    width: 100%;
  }
  .lmy {
    position: relative;
    background: #fff;
    margin-top: -128px;
    z-index: 1;
  }
  .lmy-right {
    border-bottom: 1px solid #eee;
  }
  .lmy-right-list {
    display: flex;
  }
  .lmy-right-list .zlm {
    padding: 20px 40px 20px 40px;
    .news {
      font-size: 56px;
      text-align: center;
      color: #a12916;
    }
  }
  .lmy-right-list p {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    text-align: center;
    height: 45px;
    line-height: 45px;
    color: #a12916;
  }
  .lmy-right-list p span img {
    margin-right: 5px;
  }
  .lmy-right-list ul {
    display: flex;
    width: 100%;
    height: 128px;
  }
  .lmy-right-list ul li {
    height: 128px;
    line-height: 128px;
    text-align: center;
    font-size: 20px;
    width: 166px;
    span {
      color: #333;
      cursor: pointer;
    }
  }
  .lmy-right-list ul li.current {
    background: #a12916;
    span {
      color: #fff;
      cursor: pointer;
    }
  }
  .lmy-right-list ul li:hover {
    background: #a12916;
    span {
      color: #fff;
      cursor: pointer;
    }
  }
  a {
    color: #333;
  }
  .lmy-center {
    margin-top: 25px;
    // margin-bottom: 50px;
  }
  .lmy-center .lmy-left {
    width: 1030px;
    margin: 0 auto;
  }
  .lmy-center .lmy-left .lmy-left-top {
    position: relative;
    height: 479px;
  }
  .navRoute {
    height: 14px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333;
    margin-bottom: 40px;
  }
  .lmy-center .lmy-left .lmy-left-top img {
    width: 100%;
    height: 100%;
  }
  .lmy-center .lmy-left .lmy-left-top span {
    display: block;
    position: absolute;
    background: url("../assets/img/topNews.png") no-repeat;
    width: 97px;
    height: 41px;
    font-size: 14px;
    color: #fff;
    top: 30px;
    left: -10px;
    line-height: 50px;
    text-align: center;
  }
  .ttnr {
    display: flex;
    position: absolute;
    left: 0;
    bottom: 0;
    background: url("../assets/img/maskBg1.png");
    background-repeat: repeat-x;
    color: #fff;
    width: 1030px;
    height: 165px;
    font-size: 12px;
    padding: 50px 135px 0 40px;
  }
  .ttnr-left {
    width: 60px;
    text-align: center;
    margin-top: 20px;
    margin-right: 28px;
  }
  .ttnr-left p:first-child {
    font-size: 22px;
  }
  .ttnr-right {
    width: 760px;
    .ttnr-right-bt {
      margin-bottom: 10px;
    }
  }
  .ttnr-right a {
    font-size: 22px;
    color: #fff;
  }
  .ttnr-right a:hover {
    color: #a12916;
  }
  .lmy-left-bottom {
    margin-top: 25px;
  }
  .lmy-left-bottom .news-list .news-list-item {
    height: 100px;
    border-top: 1px solid #ddd;
    background: #fff url("../assets/img/sy-vm-bg-6.png") no-repeat;
    background-position: 95% center;
    padding-left: 50px;
    display: flex;
    align-items: center;
  }
  .lmy-left-bottom .news-list .news-list-item:first-of-type {
    border-top: none;
  }
  .lmy-left-bottom .news-list .news-list-item .list-date {
    width: 50px;
    text-align: center;
    color: #a12916;
    margin-right: 50px;
    font-size: 12px;
  }
  .lmy-left-bottom .news-list .news-list-item .list-date p:first-child {
    font-size: 34px;
  }
  .lmy-left-bottom .news-list .news-list-item .list-title {
    width: 730px;
    line-height: 180%;
  }
  .lmy-left-bottom .news-list .news-list-item .list-title .li_bt {
    height: 18px;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    line-height: 25px;
    margin-bottom: 10px;
  }
  .lmy-left-bottom .news-list .news-list-item .list-title .li_zy {
    height: 15px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 25px;
  }
  .lmy-left-bottom .news-list .news-list-item:hover {
    background: #a12916 url("../assets/img/sy-vm-bg-6.png") no-repeat;
    background-position: 95% center;
    color: #fff;
    .list-date {
      color: #fff;
    }
    .list-title .li_bt {
      color: #fff;
    }
    .list-title .li_zy {
      color: #fff;
    }
  }
  .lmy-page {
    text-align: center;
    margin-top: 38px;
    padding-bottom: 65px;
  }
  ::v-deep .el-pager li.active {
    color: #a12916;
  }
  ::v-deep .el-pager li:hover {
    color: #a12916;
  }
  ::v-deep .el-pagination button:hover {
    color: #a12916;
  }
  .xgyd {
    margin-top: 50px;
  }
  .xgyd .fbt {
    color: #ababab;
    font-size: 24px;
    padding-left: 5px;
  }
  .xgyd .bt {
    height: 30px;
    line-height: 30px;
    margin-top: 10px;
  }
  .xgyd .bt i {
    float: left;
    width: 4px;
    height: 30px;
    background: #ad2e1b;
    border-radius: 2px;
  }
  .xgyd .bt .name {
    font-size: 28px;
    color: #000;
    float: left;
    margin-left: 5px;
  }
  .xgyd .bt .gd {
    float: right;
    font-size: 16px;
    color: #a12916;
  }
  .xgyd .bt .gd a {
    float: right;
    font-size: 16px;
    color: #a12916;
  }
  .xgyd .list {
    display: flex;
    flex-wrap: wrap;
  }
  .xgyd .list li {
    width: 672.5px;
    height: 108px;
    background: #fff url("../assets/img/jt.png") no-repeat;
    background-position: 95% center;
    margin-top: 20px;
    padding: 10px 20px;
  }
  .xgyd .list li:nth-of-type(2n + 1) {
    margin-right: 35px;
  }
  .xgyd .list li .date {
    float: left;
    margin-top: 17px;
  }
  .xgyd .list li .date p {
    font-size: 18px;
    height: 30px;
    line-height: 30px;
    width: 80px;
    text-align: center;
  }
  .xgyd .list li .date p:first-child {
    border-bottom: 2px solid #f9f9f9;
  }
  .xgyd .list li .list-list {
    float: left;
    height: 80px;
    margin-left: 20px;
    margin-top: 7px;
    border-left: 2px solid #f9f9f9;
    width: 440px;
    padding-top: 10px;
    line-height: 30px;
    padding-left: 10px;
    font-size: 18px;
  }
  .xgyd .list li:hover {
    width: 672.5px;
    height: 108px;
    background: #a12916 url("../assets/img/jt.png") no-repeat;
    background-position: 95% center;
    margin-top: 20px;
    padding: 10px 20px;
    color: #fff;
    a {
      color: #fff;
    }
  }
}
</style>
  